/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 16/09/2023
 * @description This React component represents a "Policies" page. It allows users to view their existing policies, add existing policies, or hire new ones. It utilizes hooks like `useTranslation` and `useNavigation` for translation and navigation respectively. The component fetches a list of policies associated with the user and calculates the total annual payment for all policies.
 *
 * The UI consists of:
 * - A widget component, displayed if there are any offers available.
 * - Two buttons for adding existing policies and hiring new ones.
 * - A section displaying the total annual payment for all policies.
 * - A policy gallery component displaying a list of policies, with options to view details or edit each policy.
 *
 * The component is structured within a Container component, with various UI elements arranged using Flex and Box components to ensure proper layout and alignment.
 */
import Container from "../../components/Container/Container";
import {Box, Button, Column, Flex, Row, Text} from "native-base";
import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {API} from "../../queries/api";
import PolicyGallery from "../../components/PolicyGallery/PolicyGallery";
import {useNavigation} from "@react-navigation/native";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import {goToUtil} from "../../utils/goTo.util";
import Widget from "../../components/Widget/Widget";
import {GlobalContext} from "../../consts/globalContext.const";
import {truncateNumUtil} from "../../utils/truncateNum.util";
import AsyncStorage from "@react-native-async-storage/async-storage";

export default function PoliciesPage() {
    const {t} = useTranslation();
    const [policies, setPolicies] = useState()
    const [totalAnnualPayment, setTotalAnnualPayment] = useState()
    const navigation = useNavigation();
    const {offersBody} = useContext(GlobalContext);

    
    const [adminPolicies, setAdminPolicies] = useState()
    const [totalAnnualPaymentAdminPolicies, setTotalAnnualPaymentAdminPolicies] = useState()

    const goTo = (policy, url) => {
        goToUtil(url, navigation, {id: policy.id, admin: policy.admin ? policy.admin : false})

    }

    const calcTotalAnnualPayment = (policies) => {
        setTotalAnnualPayment(policies.reduce((acc, policy) => policy.annualPayment + acc, 0))
    }

    const calcTotalAnnualPaymentAdminPolicies = (policies) => {
        setTotalAnnualPaymentAdminPolicies(policies.reduce((acc, policy) => policy.annualPayment + acc, 0))
    }

    useEffect(() => {
        async function getPolicies() {
            try {
                const {data} = await API("policy/policy/user/list-linked?extraFields=policyType,company");
                const userId = await AsyncStorage.getItem("userId");
                
                const ownPolicies = data.filter((policy) => policy.userId === userId)
                calcTotalAnnualPayment(ownPolicies);
                setPolicies(ownPolicies)
                
                const adminPolicies = data.filter((policy) => policy.userId !== userId)
                adminPolicies.map((policy) => policy.admin = true);

                calcTotalAnnualPaymentAdminPolicies(adminPolicies);
                setAdminPolicies(adminPolicies)
            } catch (e) {
                console.log(e)
            }
        }

        getPolicies();
    }, [])


    return <Container>
        {offersBody.count > 0 && <Box mb={6}>
            <Widget/>
        </Box>}
        <Flex flexDirection={["column", "row"]} width={"100%"}>

            <Button mb={[3, 0]} mr={[0, 3]} width={["100%", "auto"]} onPress={() => {
                goToUtil("select-insurance", navigation)
            }}>
                <Row alignItems={"center"} space={3}>
                    <Text fontSize="16" color={"#FFFFFF"}>{t("Add existing")}</Text>
                    <MaterialCommunityIcons name="file-upload-outline" color={"#FFFFFF"} size={18}/>
                </Row>
            </Button>
            <Button width={["100%", "auto"]} onPress={() => {
                goToUtil("hire-insurance", navigation)
            }}>
                <Row alignItems={"center"} space={3}>
                    <Text fontSize="16" color={"#FFFFFF"}>{t("Hire new")}</Text>
                    <MaterialCommunityIcons name="plus-circle-outline" color={"#FFFFFF"} size={18}/>
                </Row>

            </Button>
        </Flex>

        <Box mt={6}>
            <Row justifyContent={"space-between"}>
                <Column justifyContent={"flex-end"}>
                    <Text mb={3} fontSize={14}>{t("My policies")}</Text>
                </Column>
                <Column>
                    <Text textAlign="right" fontSize={14}>{t("Annual total")}</Text>
                    <Text textAlign="right" fontSize={36} fontWeight={"600"}
                          color={"secondary.700"}>{truncateNumUtil(totalAnnualPayment)}€</Text>
                </Column>
            </Row>

            <PolicyGallery data={policies} onPress={(policy) => goTo(policy, 'policies-detail')}
                           onPressBtn={(policy) => goTo(policy, 'policies-edit')} text={t("Edit")}/>
        </Box>

        <Box mt={6}>
            <Row justifyContent={"space-between"}>
                <Column justifyContent={"flex-end"}>
                    <Text mb={3} fontSize={14}>{t("My administred policies")}</Text>
                </Column>
                <Column>
                    <Text textAlign="right" fontSize={14}>{t("Annual total")}</Text>
                    <Text textAlign="right" fontSize={36} fontWeight={"600"}
                          color={"secondary.700"}>{truncateNumUtil(totalAnnualPaymentAdminPolicies)}€</Text>
                </Column>
            </Row>

            <PolicyGallery data={adminPolicies} onPress={(policy) => goTo(policy, 'policies-detail')}
                           onPressBtn={(policy) => goTo(policy, 'policies-edit')} text={t("Edit")}/>
        </Box>
    </Container>
}
