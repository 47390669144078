/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 06/10/2023
 * @description This React component represents a "Policies Edit" page. It allows users to edit details of a specific policy. It utilizes hooks like useTranslation, useNavigation, and useRoute for translation, navigation, and routing respectively. The component fetches details of the policy based on the provided ID and initializes the state with the fetched data. It provides a form for users to edit policy details. When users submit the form, it sends a PUT request to update the policy details. If there are any changes made, it navigates back to the "Policies" page upon successful update. If no changes are made, it displays a toast message informing the user that changes are required to save. The UI elements are structured within a Container component, with a heading indicating the purpose of the page and a form component for editing policy details.
 */
import Container from "../../components/Container/Container";
import {Box, Heading} from "native-base";
import {useTranslation} from "react-i18next";
import AddPolicyForm from "../../components/AddPolicyForm/AddPolicyForm";
import {useNavigation, useRoute} from "@react-navigation/native";
import {useEffect, useState} from "react";
import {API} from "../../queries/api";
import {dirtyValuesUtil} from "../../utils/dirtyValues.util";
import {transformFileLocationUtil} from "../../utils/transformFileLocation.util";
import Toast from "react-native-toast-message";
import {goToUtil} from "../../utils/goTo.util";

export default function PoliciesEditPage() {
    const {t} = useTranslation();
    const navigation = useNavigation();
    const {params: {id}} = useRoute();
    const {params: {admin}} = useRoute();
    const [policy, setPolicy] = useState()

    const savePolicyToInsurance = async (policy, dirtyFields) => {
        const dataToSubmit = dirtyValuesUtil(dirtyFields, policy)
        // is necessary parse it to Number
        if (dataToSubmit.policyTypeId) {
            dataToSubmit.policyTypeId = Number(dataToSubmit.policyTypeId)
        }

        if (Object.keys(dataToSubmit).length !== 0) {
            if (dataToSubmit.annualPayment) {
                dataToSubmit.annualPayment = parseFloat(dataToSubmit.annualPayment)
            }
            const {data} = await API.put("policy/policy/update/" + id, transformFileLocationUtil(dataToSubmit, "fileLocation"));
            goToUtil('policies', navigation)
        } else {
            Toast.show({
                type: 'info',
                text1: t("Pay attention"),
                text2: t("You need to do some change to save")
            });
        }
    }

    useEffect(() => {
        async function getPolicy() {
            try {
                const {data} = await API(`policy/policy/view${admin ? "-linked" : ""}/${id}`);
                setPolicy({
                    ...data,
                    expirationDate: new Date(data.expirationDate),
                    policyTypeId: data.policyTypeId.toString()
                })
            } catch (e) {
                console.log(e)
            }
        }

        getPolicy();
    }, [])

    return <Container>
        <Heading mb={6}>{t("Actual insurances")}</Heading>

        {policy && <Box mt={6}>
            <AddPolicyForm data={policy} onSubmit={savePolicyToInsurance}
                           onCancel={() => goToUtil('policies',navigation)}/>
        </Box>}
    </Container>
}
