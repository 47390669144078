export const es = {
    "Join account": "Entra en tu cuenta",
    "Phone": "Móvil",
    "You will receive an verification SMS code": "Recibirás un código de confirmación por SMS",
    "Don't you have account?": "¿No tienes cuenta?",
    "Register for free": "Registrate gratis",
    "Log in": "Iniciar sesión",
    "Insurances": "Seguros",
    "Add polices": "Añadir polizas",
    "Hire insurances": "Contratar seguros",
    "Promotions": "Ofertas",
    "Login": "Iniciar sesión",
    "Home": "Inicio",
    "My profile": "Mi perfil",
    "Contact data": "Datos de contacto",
    "Field is required": "El campo es requerido",
    "Email": "Email",
    "Save": "Guardar",
    "Personal data": "Datos personales",
    "Name": "Nombre",
    "First surname": "Primer apellido",
    "Second surname": "Segundo apellido",
    "DNI / NIE": "DNI / NIE",
    "Verification SMS": "Verificación SMS",
    "Please, insert code we sent you by SMS to": "Por favor, introduce el código que te hemos enviado por SMS al",
    "Send again to": "Enviar de nuevo al",
    "Send code with a call": "Recibir el código por llamada de voz",
    "is not my phone number": "no es mi número de teléfono",
    "SMS code": "Código SMS",
    "Chat Bot": "Chat bot",
    "Hello! looks like you want to hire an insurance. Complete information I will request you. Simply and fast!": "¡Hola!" +
        " parece que quieres contratar un seguro. Completa la información que te pediré ¡Es muy simple y rápido!",
    "First all I need...": "Primero que nada necesitaria...",
    "Well done! Next information I would need is...": "¡Bien hecho! La siquiente información que necesitaría es...",
    "Perfect! Keep going with": "Perfecto! sigamos con",
    "This looks so good. Please fill next field too": "Esto pinta bien. Por favor completa también el siguiente campo",
    "Slowly and carefully...well done! Neeext!": "Despacito y con buena letra...¡Bien hecho!",
    "You leave field empty. No problem, this is not mandatory. Keep going with": "Dejaste el campo vacio. No pasa nada," +
        " no" +
        " era obligatorio. Sigamos",
    "Choose an option": "Elige una opción",
    "This fill is required. You need to fill it": "Este campo es requerido. Necesitas completarlo",
    "The information you filled have a wrong format. Please change it": "La información que introdujiste tiene un" +
        " formato incorrecto. Por favor cambialo",
    "Next information will be about": "La siguiente información será sobre",
    "First all I will need data from": "Primero que nada necesitaré información de",
    "Yes": "Si",
    "No": "No",
    "I prefer not to answer": "Prefiero no responder",
    "Register": "Registro",
    "Register free": "Registrarse gratis",
    "Continue": "Continuar",
    "Do you have an account already?": "¿Ya tienes cuenta?",
    "Add policies": "Añadir pólizas",
    "Search your company": "Busca tu compañía",
    "Select companies which you have insurances hired": "Selecciona las compañias con las que tienes seguros contratados",
    "Actual insurances": "Seguros actuales",
    "Next": "Siguiente",
    "Add policy": "Agregar poliza",
    "Who or what you have insured?": "¿Quién o qué tienes asegurado?",
    "Price": "Precio",
    "to": "al",
    "ANNUAL": "Año",
    "HALF_YEARLY": "Semestre",
    "FOUR_MONTHLY": "Cuatrimestre",
    "QUARTERLY": "Trimestre",
    "BIMONTHLY": "Bimestre",
    "MONTHLY": "Mes",
    "FORTNIGHTLY": "Quincenal",
    "WEEKLY": "Semana",
    "UNIQUE": "Pago único",
    "Attach your contracts": "Adjunta tus contratos",
    "Capture your contracts": "Captura tus contratos",
    "Add the policy": "Añade la póliza",
    "Cancel": "Cancelar",
    "You can upload multiple policies from one company clicking on \"Add policy\"": "Puedes subir varias pólizas de una misma" +
        " compañía" +
        " haciendo clic en \"Añadir póliza\"",
    "Renovation date": "Fecha de renovación",
    "Frequency": "Frecuencia",
    "Policy": "Poliza",
    "Insurance type": "Tipo de seguro",
    "Policies": "Polizas",
    "Complete information": "Completar información",
    "Edit": "Editar",
    "Add existing": "Añadir existente",
    "Hire new": "Contratar nueva",
    "You need to do some change to save": "Para poder guardar necesitas hacer algún cambio",
    "Pay attention": "Ten en cuenta",
    "422": "Ha habido un problema con los datos",
    "500": "Ha habido un problema con la petición",
    "401": "No tienes los permisos suficientes",
    "My account": "Mi cuenta",
    "Profile":"Perfil",
    "Logout":"Desconectarse",
    "What insurance do you want to hire?":"¿Qué seguro quieres contratar?",
    "Number need to have 9 digits and start with 6 or 7":"El número necesita tener 9 dígitos y empezar por 6 o 7",
    "Need to be 4 digits":"Necesita tener 4 dígitos",
    "Annual total":"Total anual",
    "My policies":"Mis pólizas",
    "My administred policies":"Mis pólizas administradas",
    "Company logo":"Logo de compañia",
    "Asesor image":"Imagen de asesor",
    "Know more about me":"Saber más sobre mi",
    "Your advisor":"Tu asesor",
    "Advisor":"Asesor",
    "Offers":"Ofertas",
    "Oh! You don't have active offers":"¡Vaya! No tienes ofertas activas",
    "Request a check for your fees from insurances page or find a new insurance for you":"Pide una revisión de tus" +
        " tarifas desde la ficha de tus seguros. O bien, encuentra" +
        " fácilmente" +
        " un seguro nuevo desde aquí",
    "Request new insurance":"Pedir nuevo seguro",
    "Please, fill your data":"Por favor, rellena tus datos",
    "Complete your profile":"Completa tu perfil",
    "Improve insurance":"Mejorar seguro",
    "Call assistance":"Llamar asistencia",
    "See policy":"Ver póliza",
    "Expiration date":"Fin de cobertura",
    "Object or person secured":"Objeto o persona asegurada",
    "Reference value":"Valor de referencia",
    "Back":"Atrás",
    "Delete":"Eliminar",
    "Claim sinister":"Declarar siniestro",
    "seconds to send another SMS":"segundos para enviar otro SMS",
    "You need to wait":"Necesitas esperar",
    "Birth date":"Fecha de nacimiento",
    "Postal code":"Código postal",
    "Gender":"Género",
    "Male":"Hombre",
    "Female":"Mujer",
    "My experience":"Experiencia",
    "My education":"Educación",
    "About me":"Sobre mí",
    "No advisor":"Sin asesor",
    "Confirm":"Confirmar",
    "Are you sure?":"¿Estás seguro?",
    "You have insurances without policies. You will not save any policy from those companies":"Tienes compañias sin polizas. No guardarás ninguna poliza para esas compañías",
    "Select items":"Selecciona opciones",
    "Select":"Seleccionar",
    "Selected":"Seleccionados",
    "My insurances":"Mis seguros",
    "Search municipality":"Buscar municipio",
    "Address":"Dirección",
    "Select your province":"Selecciona tu provincia",
    "Portal number":"Número del portal",
    "Select your door":"Selecciona tu puerta",
    "Select your floor":"Selecciona tu planta",
    "Additional data":"Datos adicionales",
    "Search address":"Buscar dirección",
    "Legal Advise":"Aviso Legal",
    "Who are we?":"¿Quienes somos?",
    "Social Privacy Policy":"Política de privacidad en Redes Sociales",
    "Privacy Policy and Cookies":"Política de Privacidad y Cookies",
    "Cookies Policy":"Política de cookies",
    "Frequent Answers":"Preguntas Frecuentes",
    "Consent for Data Treatment":"Consentimiento para el Tratamiento de Datos",
    "I accept the": "Acepto el",
    "and the":"y la",
    "from":"desde",
    "Valid to":"Válido hasta",
    "Preparing your proposal":"Preparando tu propuesta",
    "New insurance":"Nuevo seguro",
    "BEST_PRICE":"Mejor precio",
    "BEST_COVERAGE":"Mejores coberturas",
    "BEST_RECOMMENDED":"Mejor precio y coberturas",
    "I want it":"Me interesa",
    "Budget selected":"Presupuesto seleccionado",
    "Offers pending":"Ofertas en curso",
    "We are preparing your offers":"Estamos preparando tus ofertas",
    "your age need to be over":"tu edad tiene que ser mayor o igual que",
    "For the license":"Para la licencia",
    "Verification":"Verificación",
    "New proposal":"Nueva propuesta",
    "Hire insurance":"Contratar seguro",
    "Hire Offer":"Contratar oferta",
    "Use an actual address":"Usar una dirección existente",
    "Create new address":"Crear nueva dirección",
    "Bank account (IBAN)":"Cuenta bancaria (IBAN)",
    "Introduce a value":"Introduce un valor",
    "Go back":"Volver",
    "Confirm data":"Confirmar datos",
    "I prefer to get call by my advisor":"Prefiero que me llame mi asesor",
    "Initial coverage date":"Fecha inicio cobertura",
    "Hire":"Contratar",
    "This fill is required. You need to search a real address and select one from the provided list":"El campo es requerido. Necesitas buscar una dirección real y seleccionarla de la lista facilitada",
    "We have received your data and we are going to emit your policy. In 48 hours you will see it in your APP. Thanks for trust in Prudenzia":"Hemos recibido tus datos correctamente y procederemos a realizar la emisión de tu póliza. En 48 horas podrás verla en tu APP. Muchas gracias por confiar en Prudenzia",
    "Great! I will contact you as soon as possible and we will resolve your doubts":"¡Estupendo! Me pongo en contacto contigo en la mayor brevedad posible y resolvemos tus dudas",
    "This fill is required. You need select a valid date":"El campo es requerido. Necesitas seleccionar una fecha válida",
    "This fill is required. You need to check it":"El campo es requerido. Necesitas marcarlo",
    "This fill is required. You need to select one option":"El campo es requerido. Necesitas seleccionar una opción",
    "This fill is required. You need select at least one option":"El campo es requerido. Necesitas seleccionar al menos una opción",
    "This fill is required. You need to insert a number":"El campo es requerido. Necesitas introducir un número",
    "You need to insert a valid Spanish IBAN":"Necesitas insertar un IBAN válido español",
    "Coverages":"Coberturas",
    "See PDF":"Ver PDF",
    "See detail":"Ver detalle",
    "No coverages":"Sin coberturas",
    "File need to be maximum 5MB":"El fichero necesita ser como máximo de 5MB",
    "Max size exceeded":"Tamaño máximo excedido",
    "Policy number":"Número de póliza",
    "Policy Camera": "Camára de pólizas",
    "Failed to generate policy from camera": "Error al generar póliza con la cámara"
}
