/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 22/01/2024
 * @description This React component represents an "Offers Hire" page. It manages the hiring process for an insurance policy based on the provided ID. It handles multiple steps of the process using the useState hook for step tracking. It also fetches proposal details, budget information, and company details associated with the accepted budget. The component utilizes hooks like useRoute, useState, useEffect, and useTranslation for routing, state management, lifecycle methods, and translation respectively. It renders different components based on the step and proposal status, allowing users to confirm and hire insurance policies. It also handles address creation, effective date selection, and payment method updates. The UI elements are structured within a Container component, with conditional rendering based on the step and proposal status.
 */
import Container from "../../components/Container/Container";
import React, {useEffect, useState} from "react";
import {useNavigation, useRoute} from "@react-navigation/native";
import OffersHireConfirm from "./components/OffersHireConfirm/OffersHireConfirm";
import OffersHireCheck from "./components/OffersHireCheck/OffersHireCheck";
import {API} from "../../queries/api";
import {useTranslation} from "react-i18next";
import OffersHireInfo from "./components/OffersHireInfo/OffersHireInfo";
import {goToUtil} from "../../utils/goTo.util";
import {Text} from "native-base";

export default function OffersHirePage() {
    const {params: {id}} = useRoute();
    const [step, setStep] = useState(0)
    const [effectiveDate, setEffectiveDate] = useState()
    const [callAdvisorSelected, setCallAdvisorSelected] = useState(false)
    const [budget, setBudget] = useState()
    const [proposal, setProposal] = useState({})
    const {t} = useTranslation()
    const navigation = useNavigation()
    const onHire = async (fData) => {
        let savedAddress;
        if (!fData.addressId) {
            savedAddress = (await postAddress(fData)).data;
        }

        await API.put("policy/proposal/paymentMethodUpdate/" + id, {
            effectiveDate,
            iban: fData.iban,
            anotherOwner: false,
            addressId: savedAddress ? savedAddress.id : Number(fData.addressId)
        }) // by default anotherOwner is false
        setStep(3)
    }
    const onCheck = async ({date}) => {
        setEffectiveDate(date);
        setStep(2)
    }

    const postAddress = async (address) => {
        return API.post("user/address/create", {
            "active": true,
            "buildNumber": address["address_cadastral_reference"],
            "city": address["address_town"],
            "door": address["address_door"],
            "floor": address["address_floor"],
            "postalCode": address["address_postal"],
            "province": address["province"],
            "street": address["address"],
            "streetType": address["address_road_type"],
            "title": address["address"],
            "userId": proposal.userId
        })
    }

    const getProposal = async (id) => {
        return API("policy/proposal/view/" + id)
    }

    const callAdvisor = async () => {
        await API.post(
            `task/payment-method/call/` + id
        );
        setCallAdvisorSelected(true);
        setStep(3);
    }



    const getBudget = async (id) => {
        return API("policy/budget/view/" + id + "?extraFields=budgetCoverages")
    }

    useEffect(() => {
        const init = async () => {
            const {data: proposal} = await getProposal(id);
            if (proposal.acceptedBudgetId) {
                const {data: budget} = await getBudget(proposal.acceptedBudgetId);
                const {data: company} = await API(`policy/company/view/${budget.companyId}`);
                setProposal(proposal)
                setBudget({...budget, company})
            } else {
                goToUtil("offers-detail", navigation, {id: proposal.id})
            }
        }
        init()
    }, []);

    return <Container>

        {proposal.status === "ACCEPTED" && <>
            {step === 0 && budget && <OffersHireInfo budget={budget} policyTypeId={proposal.policyTypeId} onHireInsurance={() => setStep(1)}/>}

            {step === 1 && <OffersHireCheck onCallAdvisor={callAdvisor} onSubmit={onCheck}/>}

            {step === 2 &&
                <OffersHireConfirm proposalId={id}
                                   onSubmit={onHire}
                                   userId={proposal.userId}
                                   onGoBack={() => setStep(1)}/>}

        </>}
        {
            (step === 3 || proposal.status === "POLICY_PENDING") && 
            <Text>
                {
                    callAdvisorSelected ?
                    t("Great! I will contact you as soon as possible and we will resolve your doubts") :
                    t("We have received your data and we are going to emit your policy. In 48 hours you will see it in your APP. Thanks for trust in Prudenzia")
                }.
            </Text>
        }
    </Container>
}
